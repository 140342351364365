define("ember-light-table/templates/components/lt-spanned-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CECAlaJo",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"tr\"],[15,0,[29,[\"lt-row \",[28,[37,2],[[33,3]],null]]]],[12],[1,\"\\n    \"],[10,\"td\"],[15,\"colspan\",[36,4]],[12],[1,\"\\n      \"],[18,1,[[33,5]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"visible\",\"html-safe\",\"classes\",\"colspan\",\"yield\"]]",
    "moduleName": "ember-light-table/templates/components/lt-spanned-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});