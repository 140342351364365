define("ember-light-table/templates/components/cells/base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmJJ0rMq",
    "block": "[[[41,[33,1,[\"cellComponent\"]],[[[1,\"  \"],[46,[33,1,[\"cellComponent\"]],null,[[\"tableActions\",\"extra\",\"table\",\"column\",\"row\",\"value\",\"rawValue\"],[[33,3],[33,4],[33,5],[33,1],[33,6],[33,7],[33,8]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,7]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"column\",\"component\",\"tableActions\",\"extra\",\"table\",\"row\",\"value\",\"rawValue\"]]",
    "moduleName": "ember-light-table/templates/components/cells/base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});