define("bgr-ember-flash/services/bgr-flash/flash", ["exports", "bgr-ember-flash/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend(Ember.Evented, {
    /**
     * props
     */
    level: null,
    message: null,
    options: null,
    service: null,

    /**
     * state
     */
    timer: null,

    /**
     * methods
     */
    hide() {
      Ember.run.cancel(this.get('timer'));

      this.get('service')._hideFlash(this);

      this.trigger('hide');
      return this;
    },

    show() {
      let options = this.get('options');

      this.get('service')._showFlash(this);

      this.trigger('show');

      if (!options.isSticky) {
        this.set('timer', Ember.run.later(() => {
          this.hide();
        }, _config.default.flashWait + options.timeout));
      }

      return this;
    }

  });

  _exports.default = _default;
});