define("bgr-ember-flash/templates/components/bgr-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DORaYSMZ",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"hide\",\"level\",\"message\",\"options\"],[[28,[37,4],[[30,0],\"hide\"],null],[33,5],[33,6],[33,7]]]]]],[1,\"\\n\"]],[]],[[[41,[33,7,[\"title\"]],[[[1,\"    \"],[10,\"h4\"],[14,0,\"flash__title\"],[12],[2,[33,7,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,2],[14,0,\"flash__message\"],[12],[2,[36,6]],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"flash__hide-button\"],[4,[38,4],[[30,0],\"hide\"],null],[12],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"action\",\"level\",\"message\",\"options\"]]",
    "moduleName": "bgr-ember-flash/templates/components/bgr-flash.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});