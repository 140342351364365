define("ember-cli-head/services/head-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HeadData extends Ember.Service {}

  _exports.default = HeadData;
});