define("bgr-ember-flash/initializers/bgr-ember-flash", ["exports", "bgr-ember-addon-blueprint/utils/merge-config", "bgr-ember-flash/config"], function (_exports, _mergeConfig, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(application) {
    let environment = application.resolveRegistration('config:environment') || {};
    (0, _mergeConfig.default)(_config.default, environment['bgr-ember-flash']);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});