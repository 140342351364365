define("@bagaar/ember-breadcrumbs/components/breadcrumbs-item/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.breadcrumbsService.containers as |container|}}
    {{#in-element container.element insertBefore=null}}
      <li class={{container.itemClass}} ...attributes>
        {{yield container.linkClass}}
      </li>
    {{/in-element}}
  {{/each}}
  */
  {
    "id": "pqWMess6",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"breadcrumbsService\",\"containers\"]]],null]],null],null,[[[40,[[[1,\"    \"],[11,\"li\"],[16,0,[30,1,[\"itemClass\"]]],[17,2],[12],[1,\"\\n      \"],[18,3,[[30,1,[\"linkClass\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,1,[\"element\"]]],null],null]],[1]],null]],[\"container\",\"&attrs\",\"&default\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\",\"yield\"]]",
    "moduleName": "@bagaar/ember-breadcrumbs/components/breadcrumbs-item/index.hbs",
    "isStrictMode": false
  });

  let BreadcrumbsItemComponent = (_dec = Ember.inject.service('breadcrumbs'), (_class = class BreadcrumbsItemComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "breadcrumbsService", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "breadcrumbsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BreadcrumbsItemComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BreadcrumbsItemComponent);
});