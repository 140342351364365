define("ember-composable-helpers/helpers/compact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;

  function compact(_ref) {
    let [value] = _ref;
    let array;

    if (Array.isArray(value) || Ember.isArray(value)) {
      array = value;
    } else {
      array = [value];
    }

    return array.filter(item => Ember.isPresent(item));
  }

  var _default = Ember.Helper.helper(compact);

  _exports.default = _default;
});