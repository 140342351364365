define("ember-data-copyable/index", ["exports", "ember-data-copyable/mixins/copyable"], function (_exports, _copyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _copyable.default;
    }
  });
});