define('bgr-ember-modal/components/bgr-modal-stack', ['exports', 'bgr-ember-modal/templates/components/bgr-modal-stack'], function (exports, _bgrModalStack) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * state
     */

    bgrModal: Ember.inject.service(),
    classNames: ['modal-stack'],
    layout: _bgrModalStack.default,

    /**
     * computed
     */

    modalStack: Ember.computed.readOnly('bgrModal.modalStack')
  });
});