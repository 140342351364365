define('ember-scrollable/util/timeout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.timeout = timeout;
  function timeout(ms) {
    let promise = new Ember.RSVP.Promise(r => {
      Ember.run.later(r, ms);
    });
    return promise;
  }
});