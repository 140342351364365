define("bgr-ember-flash/services/bgr-flash/index", ["exports", "bgr-ember-flash/services/bgr-flash/public-api", "bgr-ember-flash/services/bgr-flash/private-api"], function (_exports, _publicApi, _privateApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_publicApi.default, _privateApi.default);

  _exports.default = _default;
});