define("ember-light-table/components/lt-head", ["exports", "ember-light-table/templates/components/lt-head", "ember-light-table/mixins/table-header"], function (_exports, _ltHead, _tableHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @module Light Table
   */

  /**
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{t.head onColumnClick=(action 'sortByColumn')}}
   * {{/light-table}}
   * ```
   *
   * If you want to define your own thead, just declare the contextual component in a block.
   *
   * ```hbs
   * {{#light-table table as |t|}}
   *   {{#t.head onColumnClick=(action 'sortByColumn') as |groups subColumns|}}
   *     {{#each groups as |group|}}
   *       {{!-- ... --}}
   *     {{/each}}
   *   {{/t.head}}
   * {{/light-table}}
   * ```
   *
   * If you dont have grouped columns, the yielded `groups` will be an array of all visibile columns and `subColumns`
   * will be empty
   *
   * @class t.head
   * @uses TableHeaderMixin
   */
  var _default = Ember.Component.extend(_tableHeader.default, {
    layout: _ltHead.default,
    classNames: ['lt-head-wrap'],
    table: null,
    sharedOptions: null,
    sharedOptionsFixedKey: 'fixedHeader'
  });

  _exports.default = _default;
});