define("ember-attacher/components/attach-tooltip", ["exports", "@ember-decorators/object", "ember-attacher/components/attach-popover", "ember-attacher/defaults"], function (_exports, _object, _attachPopover, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let AttachTooltip = (_dec = Ember.computed('_config.tooltipClass'), _dec2 = (0, _object.observes)('popperTarget'), classic(_class = (_class2 = class AttachTooltip extends _attachPopover.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "configKey", 'tooltip');

      _defineProperty(this, "ariaRole", 'tooltip');
    }

    get class() {
      return this._config.tooltipClass || _defaults.default.tooltipClass;
    }

    set class(value) {
      const tooltipClass = this._config.tooltipClass || _defaults.default.tooltipClass; // eslint-disable-next-line no-setter-return

      return `${tooltipClass} ${value}`;
    }

    didInsertElement() {
      super.didInsertElement(...arguments);

      if (!this._currentTarget) {
        return;
      }

      this._currentTarget.setAttribute('aria-describedby', this.id);
    }

    popperTargetChanged() {
      const oldTarget = this._currentTarget;

      if (oldTarget) {
        oldTarget.removeAttribute('aria-describedby');
      }

      super.popperTargetChanged;
      this.popperTarget.setAttribute('aria-describedby', this.id);
    }

    willDestroyElement() {
      super.willDestroyElement(...arguments);
      const target = this._currentTarget;

      if (target) {
        target.removeAttribute('aria-describedby');
      }
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "class", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "class"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperTargetChanged", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "popperTargetChanged"), _class2.prototype)), _class2)) || _class);
  _exports.default = AttachTooltip;
});