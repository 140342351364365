define('ember-component-inbound-actions/helpers/send', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(([target, action, ...params]) => {
    return () => {
      target.send(action, ...params);
    };
  });
});