define("ember-light-table/components/lt-infinity", ["exports", "ember-light-table/templates/components/lt-infinity"], function (_exports, _ltInfinity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _ltInfinity.default,
    inViewport: Ember.inject.service(),
    classNames: ['lt-infinity'],
    scrollableContent: null,
    scrollBuffer: 50,

    didInsertElement() {
      this._super(...arguments);

      const options = {
        viewportSpy: true,
        viewportTolerance: {
          bottom: this.get('scrollBuffer')
        },
        scrollableArea: this.get('scrollableContent')
      };
      const {
        onEnter,
        onExit
      } = this.get('inViewport').watchElement(Ember.get(this, 'element'), options);
      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    },

    willDestroyElement() {
      this.get('inViewport').stopWatching(this.get('element'));
    },

    didEnterViewport() {
      Ember.get(this, 'enterViewport')();
    },

    didExitViewport() {
      Ember.get(this, 'exitViewport')();
    }

  });

  _exports.default = _default;
});