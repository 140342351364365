define("bgr-ember-flash/components/bgr-flash", ["exports", "bgr-ember-flash/config", "bgr-ember-flash/templates/components/bgr-flash"], function (_exports, _config, _bgrFlash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * props
     */
    instance: null,

    /**
     * state
     */
    classNameBindings: ['isActive:is-active:is-inactive', 'levelModifierClass'],
    classNames: ['flash'],
    isActive: false,
    later: null,
    layout: _bgrFlash.default,

    /**
     * computed
     */
    level: Ember.computed.readOnly('instance.level'),
    message: Ember.computed.readOnly('instance.message'),
    options: Ember.computed.readOnly('instance.options'),
    levelModifierClass: Ember.computed('level', function () {
      return `flash--${this.get('level')}`;
    }),

    /**
     * hooks
     */
    didInsertElement() {
      this._super(...arguments);

      this.set('later', Ember.run.later(() => {
        this.set('isActive', true);
      }, _config.default.flashWait));
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.cancel(this.get('later'));
    },

    /**
     * actions
     */
    actions: {
      hide() {
        this.hide();
      }

    },

    /**
     * methods
     */
    hide() {
      this.get('instance').hide();
    }

  });

  _exports.default = _default;
});