define("ember-composable-helpers/helpers/reduce", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reduce = reduce;

  function reduce(_ref) {
    let [callback, initialValue, array] = _ref;

    if (Ember.isEmpty(callback)) {
      return [];
    }

    return (0, _asArray.default)(array).reduce(callback, initialValue);
  }

  var _default = Ember.Helper.helper(reduce);

  _exports.default = _default;
});