define('bgr-ember-modal/services/bgr-modal/modal', ['exports', '@ember/string', 'bgr-ember-modal/config'], function (exports, _string, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.Evented, {
    /**
     * props
     */

    model: null,
    name: null,
    options: null,
    service: null,

    /**
     * state
     */

    deferred: null,

    /**
     * computed
     */

    path: Ember.computed('name', function () {
      let modalNamespace = _config.default.modalNamespace;
      let name = (0, _string.loc)(_config.default.modalNameFormat, this.get('name'));

      return modalNamespace ? `${modalNamespace}/${name}` : name;
    }),

    /**
     * methods
     */

    add() {
      this.get('service')._addModal(this);

      this.trigger('add');
      this.trigger('show');

      return this.promise();
    },

    hide() {
      this.get('service')._hideModal(this);

      this.trigger('hide');

      return this;
    },

    promise() {
      let deferred = Ember.RSVP.defer();

      this.set('deferred', deferred);

      return deferred.promise;
    },

    reject(error) {
      this.hide();

      this.get('deferred').reject(error);

      return this;
    },

    resolve(model) {
      this.hide();

      this.get('deferred').resolve(model ? model : this.get('model'));

      return this;
    },

    show() {
      this.get('service')._showModal(this);

      this.trigger('show');

      return this.promise();
    }
  });
});