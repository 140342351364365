define("ember-composable-helpers/helpers/group-by", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.groupBy = groupBy;

  function groupBy(_ref) {
    let [byPath, array] = _ref;
    let groups = {};
    (0, _asArray.default)(array).forEach(item => {
      let groupName = Ember.get(item, byPath);
      let group = groups[groupName];

      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }

      group.push(item);
    });
    return groups;
  }

  var _default = Ember.Helper.helper(groupBy);

  _exports.default = _default;
});