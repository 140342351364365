define("ember-text-measurer/services/text-measurer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.canvas = document.createElement('canvas');
      this.ctx = this.canvas.getContext('2d');
    },

    width(string) {
      let font = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (font) {
        this.ctx.font = font;
      }

      return this.ctx.measureText(string).width;
    },

    lines(string, maxWidth) {
      let font = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (font) {
        this.ctx.font = font;
      }

      let paragraphs = string.split(/\n/);
      let lines = paragraphs.length;

      for (let i = 0; i < paragraphs.length; i++) {
        let paragraph = paragraphs[i];

        if (paragraph !== '') {
          let words = paragraph.split(' ');
          let widthSoFar = 0;
          let j = 0;

          for (; j < words.length - 1; j++) {
            let wordWidth = this.ctx.measureText(words[j] + ' ').width;
            widthSoFar = widthSoFar + wordWidth;

            if (widthSoFar > maxWidth) {
              lines++;
              widthSoFar = wordWidth;
            }
          }

          let wordWidth = this.ctx.measureText(words[j]).width;
          widthSoFar = widthSoFar + wordWidth;

          if (widthSoFar > maxWidth) {
            lines++;
            widthSoFar = wordWidth;
          }
        }
      }

      return lines;
    },

    fitTextSize(string, maxWidth) {
      let font = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      let width = this.width(string, font);
      let fontSize = this.ctx.font.match(/\d+/)[0];
      return Math.floor(parseFloat(fontSize) * maxWidth / width);
    }

  });

  _exports.default = _default;
});