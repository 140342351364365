define("bgr-ember-flash/services/bgr-flash/private-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * methods
     */
    _hideFlash(flash) {
      this.get('flashStack').removeObject(flash);
    },

    _showFlash(flash) {
      this.get('flashStack').addObject(flash);
    }

  });

  _exports.default = _default;
});