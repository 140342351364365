define('ember-scrollable/util/css', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function styleify(obj) {
    if (Ember.isEmpty(obj)) {
      return Ember.String.htmlSafe('');
    }
    const styles = Object.keys(obj).reduce((styleString, key) => {
      const styleValue = obj[key];
      if (!Ember.isEmpty(styleValue)) {
        styleString += `${key}: ${styleValue}; `;
      }
      return styleString;
    }, '');
    return Ember.String.htmlSafe(styles);
  }

  exports.styleify = styleify;
});