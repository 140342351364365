define("ember-app-scheduler/helpers/route-idle", ["exports", "ember-app-scheduler/scheduler"], function (_exports, _scheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RouteIdle extends Ember.Helper {
    compute() {
      return _scheduler.default.isIdle;
    }

  }

  _exports.default = RouteIdle;
});