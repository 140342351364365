define("@bagaaravel/ember-data-extensions/adapter", ["exports", "@bagaaravel/ember-data-extensions/-private/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.urlForUpdateRecord = urlForUpdateRecord;

  function urlForUpdateRecord(baseUrl, id, modelName, snapshot) {
    const relationshipName = (0, _utils.getRelationshipName)(snapshot.adapterOptions);
    const isSavingRelationship = Boolean(relationshipName);

    if (isSavingRelationship) {
      return `${baseUrl}/relationships/${relationshipName}`;
    }

    return baseUrl;
  }
});