define("@bagaar/ember-breadcrumbs/components/breadcrumbs-container/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ul
    {{breadcrumbs-container itemClass=@itemClass linkClass=@linkClass}}
    ...attributes
  >
    {{yield}}
  </ul>
  */
  {
    "id": "YbSb6DJn",
    "block": "[[[11,\"ul\"],[17,1],[4,[38,0],null,[[\"itemClass\",\"linkClass\"],[[30,2],[30,3]]]],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@itemClass\",\"@linkClass\",\"&default\"],false,[\"breadcrumbs-container\",\"yield\"]]",
    "moduleName": "@bagaar/ember-breadcrumbs/components/breadcrumbs-container/index.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});