define("ember-light-table/classes/Table", ["exports", "ember-light-table/classes/Row", "ember-light-table/classes/Column", "ember-light-table/-private/sync-array-proxy", "ember-light-table/-private/global-options", "ember-light-table/utils/fix-proto"], function (_exports, _Row, _Column, _syncArrayProxy, _globalOptions, _fixProto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const RowSyncArrayProxy = _syncArrayProxy.default.extend({
    serializeContentObjects(objects) {
      return Table.createRows(objects);
    },

    serializeSyncArrayObjects(objects) {
      return objects.map(o => Ember.get(o, 'content'));
    }

  });
  /**
   * @module Table
   * @private
   */

  /**
   * @module Table
   * @class Table
   */


  class Table extends Ember.Object.extend({
    /**
     * @property columns
     * @type {Ember.Array}
     * @default []
     */
    columns: null,

    /**
     * @property rows
     * @type {Ember.Array}
     * @default []
     */
    rows: null,

    /**
     * @property isEmpty
     * @type {Boolean}
     */
    isEmpty: Ember.computed.empty('rows').readOnly(),

    /**
     * @property expandedRows
     * @type {Ember.Array}
     */
    expandedRows: Ember.computed.filterBy('rows', 'expanded', true).readOnly(),

    /**
     * @property selectedRows
     * @type {Ember.Array}
     */
    selectedRows: Ember.computed.filterBy('rows', 'selected', true).readOnly(),

    /**
     * @property visibleRows
     * @type {Ember.Array}
     */
    visibleRows: Ember.computed.filterBy('rows', 'hidden', false).readOnly(),

    /**
     * @property sortableColumns
     * @type {Ember.Array}
     */
    sortableColumns: Ember.computed.filterBy('visibleColumns', 'sortable', true).readOnly(),

    /**
     * @property sortedColumns
     * @type {Ember.Array}
     */
    sortedColumns: Ember.computed.filterBy('visibleColumns', 'sorted', true).readOnly(),

    /**
     * @property hideableColumns
     * @type {Ember.Array}
     */
    hideableColumns: Ember.computed.filterBy('allColumns', 'hideable', true).readOnly(),

    /**
     * @property hiddenColumns
     * @type {Ember.Array}
     */
    hiddenColumns: Ember.computed.filterBy('allColumns', 'hidden', true).readOnly(),

    /**
     * @property responsiveHiddenColumns
     * @type {Ember.Array}
     */
    responsiveHiddenColumns: Ember.computed.filterBy('allColumns', 'responsiveHidden', true).readOnly(),

    /**
     * @property visibleColumns
     * @type {Ember.Array}
     */
    visibleColumns: Ember.computed.filterBy('allColumns', 'isHidden', false).readOnly(),

    /**
     * @property visibleColumnGroups
     * @type {Ember.Array}
     */
    visibleColumnGroups: Ember.computed('columns.[]', 'columns.@each.{isHidden,isVisibleGroupColumn}', function () {
      return this.get('columns').reduce((arr, c) => {
        if (c.get('isVisibleGroupColumn') || !c.get('isGroupColumn') && !c.get('isHidden')) {
          arr.pushObject(c);
        }

        return arr;
      }, Ember.A([]));
    }).readOnly(),

    /**
     * @property visibleSubColumns
     * @type {Ember.Array}
     */
    visibleSubColumns: Ember.computed('columns.@each.visibleSubColumns', function () {
      return Ember.A([].concat(...this.get('columns').getEach('visibleSubColumns')));
    }).readOnly(),

    /**
     * @property allColumns
     * @type {Ember.Array}
     */
    allColumns: Ember.computed('columns.@each.subColumns', function () {
      return this.get('columns').reduce((arr, c) => {
        arr.pushObjects(c.get('isGroupColumn') ? c.get('subColumns') : [c]);
        return arr;
      }, Ember.A([]));
    }).readOnly(),

    /**
     * @class Table
     * @constructor
     * @param  {Object} options
     * @param  {Array} options.columns
     * @param  {Array} options.rows
     * @param  {Boolean} options.enableSync If `true`, creates a two way sync
     *           between the table's rows and the passed rows collection. Also see
     *           `setRowsSynced(rows)`.
     * @param  {Object}  options.rowOptions Options hash passed through to
     *           `createRow(content, options)`.
     */
    init() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let {
        columns = [],
        rows = []
      } = options;
      (true && !(Ember.isArray(columns)) && Ember.assert('[ember-light-table] columns must be an array if defined', Ember.isArray(columns)));
      (true && !(Ember.isArray(rows)) && Ember.assert('[ember-light-table] rows must be an array if defined', Ember.isArray(rows)));
      this.setProperties((0, _globalOptions.mergeOptionsWithGlobals)(options));
      this.set('columns', Ember.A(Table.createColumns(columns)));

      let _rows = Ember.A(Table.createRows(rows, this.get('rowOptions')));

      if (this.get('enableSync')) {
        _rows = RowSyncArrayProxy.create({
          syncArray: rows,
          content: _rows
        });
      }

      this.set('rows', _rows);
    }

  }) {
    destroy() {
      this._super(...arguments);

      let rows = this.get('rows');

      if (rows instanceof RowSyncArrayProxy) {
        rows.destroy();
      }
    } // Rows

    /**
     * Replace all the row's content with content of the argument. If argument is an empty array rows will be cleared.
     * @method setRows
     * @param  {Array} rows
     * @param  {Object} options
     * @return {Array} rows
     */


    setRows() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.get('rows').setObjects(Table.createRows(rows, options));
    }
    /**
     * The same as `setRows`, however the given array is synced, meaning that
     * mutating the array also updates the table and vice-versa.
     *
     * Also see `enableSync` in the constructor options.
     *
     * @method setRowsSynced
     * @param  {Array} rows
     * @param  {Object} options
     * @return {Array} rows
     */


    setRowsSynced() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      let _rows = RowSyncArrayProxy.create({
        syncArray: rows,
        content: Ember.A(Table.createRows(rows, options))
      });

      return this.set('rows', _rows);
    }
    /**
     * Push the object onto the end of the row array if it is not already present.
     * @method addRow
     * @param  {Object} row
     * @param  {Object} options
     */


    addRow(row) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (row instanceof _Row.default) {
        this.get('rows').addObject(row);
      } else if (Ember.isNone(this.get('rows').findBy('content', row))) {
        this.pushRow(row, options);
      }
    }
    /**
     * Push the objects onto the end of the row array if it is not already present.
     * @method addRows
     * @param  {Array} rows
     * @param  {Object} options
     */


    addRows() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      rows.forEach(r => this.addRow(r, options));
    }
    /**
     * Push the object onto the end of the row array.
     * @method pushRow
     * @param  {Object} row
     * @param  {Object} options
     * @return {Row} pushed row
     */


    pushRow(row) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      let _row = Table.createRow(row, options);

      this.get('rows').pushObject(_row);
      return _row;
    }
    /**
     * Push the object onto the end of the row array.
     * @method pushRows
     * @param  {Array}  rows
     * @param  {Object} options
     * @return {Array} pushed rows
     */


    pushRows() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      let _rows = Table.createRows(rows, options);

      this.get('rows').pushObjects(_rows);
      return _rows;
    }
    /**
     * Insert a row at the specified index.
     * @method insertRowAt
     * @param  {Number}  index
     * @param  {Object}  row
     * @param  {Object} options
     * @return {Row} inserted row
     */


    insertRowAt(index, row) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      let _row = Table.createRow(row, options);

      this.get('rows').insertAt(index, _row);
      return _row;
    }
    /**
     * Remove all occurrences of an object in the rows
     * @method removeRow
     * @param  {Object}  row
     */


    removeRow(row) {
      if (row instanceof _Row.default) {
        this.get('rows').removeObject(row);
      } else {
        this.get('rows').removeObjects(this.get('rows').filterBy('content', row));
      }
    }
    /**
     * Removes each object in the passed enumerable from the rows.
     * @method removeRows
     * @param  {Array}    rows
     */


    removeRows() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      rows.forEach(r => this.removeRow(r));
    }
    /**
     * Remove a row at the specified index
     * @method removeRowAt
     * @param  {Number}  index
     */


    removeRowAt(index) {
      this.get('rows').removeAt(index);
    } // Columns

    /**
     * Replace all the column's content with content of the argument. If argument is an empty array columns will be cleared.
     * @method setColumns
     * @param  {Array} columns
     * @return {Array} columns
     */


    setColumns() {
      let columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return this.get('columns').setObjects(Table.createColumns(columns));
    }
    /**
     * Push the object onto the end of the column array if it is not already present.
     * @method addColumn
     * @param  {Object} column
     */


    addColumn(column) {
      this.get('columns').addObject(Table.createColumn(column));
    }
    /**
     * Push the objects onto the end of the column array if it is not already present.
     * @method addColumns
     * @param  {Array} columns
     */


    addColumns() {
      let columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      this.get('columns').addObjects(Table.createColumns(columns));
    }
    /**
     * Push the object onto the end of the column array.
     * @method pushColumn
     * @param  {Object} column
     * @return {Column} pushed column
     */


    pushColumn(column) {
      let _column = Table.createColumn(column);

      this.get('columns').pushObject(_column);
      return _column;
    }
    /**
     * Push the object onto the end of the column array.
     * @method pushColumns
     * @param  {Array}  columns
     * @return {Array} pushed columns
     */


    pushColumns() {
      let columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      let _columns = Table.createColumns(columns);

      this.get('columns').pushObjects(_columns);
      return _columns;
    }
    /**
     * Insert a column at the specified index.
     * @method insertColumnAt
     * @param  {Number}  index
     * @param  {Object}  column
     * @return {Column} inserted column
     */


    insertColumnAt(index, column) {
      let _column = Table.createColumn(column);

      this.get('columns').insertAt(index, _column);
      return _column;
    }
    /**
     * Remove all occurrences of an object in the columns
     * @method removeColumn
     * @param  {Object}  column
     */


    removeColumn(column) {
      return this.get('columns').removeObject(column);
    }
    /**
     * Removes each object in the passed enumerable from the columns.
     * @method removeColumns
     * @param  {Array}    columns
     */


    removeColumns() {
      let columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return this.get('columns').removeObjects(columns);
    }
    /**
     * Remove a column at the specified index
     * @method removeColumnAt
     * @param  {Number}  index
     */


    removeColumnAt(index) {
      this.get('columns').removeAt(index);
    }
    /**
     * Create a Row object with the given content
     * @method createRow
     * @static
     * @param  {Object}  content
     * @param  {Object}  options
     * @return {Row}
     */


    static createRow(content) {
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (content instanceof _Row.default) {
        return content;
      } else {
        return _Row.default.create(Ember.assign({}, options, {
          content
        }));
      }
    }
    /**
     * Create a collection of Row objects with the given collection
     * @method createRows
     * @static
     * @param  {Array}  rows
     * @param  {Object} options
     * @return {Array}
     */


    static createRows() {
      let rows = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return rows.map(r => Table.createRow(r, options));
    }
    /**
     * Create a Column object with the given options
     * @method createColumn
     * @static
     * @param  {Object}  column
     * @return {Column}
     */


    static createColumn(column) {
      if (column instanceof _Column.default) {
        return column;
      } else {
        return _Column.default.create(column);
      }
    }
    /**
     * Create a collection of Column objects with the given collection
     * @method createColumns
     * @static
     * @param  {Array}  columns
     * @return {Array}
     */


    static createColumns() {
      let columns = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return columns.map(c => Table.createColumn(c));
    }

  } // https://github.com/offirgolan/ember-light-table/issues/436#issuecomment-310138868


  _exports.default = Table;
  (0, _fixProto.default)(Table);
});