define("ember-ajax/utils/ajax", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ajax = typeof FastBoot === 'undefined' ? _jquery.default.ajax : FastBoot.require('najax');
  var _default = ajax;
  _exports.default = _default;
});