define("ember-data-copyable/utils/is-undefined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isUndefined;

  function isUndefined(o) {
    return typeof o === 'undefined';
  }
});