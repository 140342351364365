define('bgr-ember-modal/services/bgr-modal/public-api', ['exports', 'bgr-ember-modal/services/bgr-modal/modal'], function (exports, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function assertion(key, value) {
    (true && !(value) && Ember.assert(`The '${key}' parameter is required.`, value));
  }

  exports.default = Ember.Mixin.create({
    /**
     * state
     */

    modalStack: Ember.A([]),
    upperModal: Ember.computed.readOnly('modalStack.lastObject'),

    /**
     * methods
     */

    add() {
      return this.create(...arguments).add();
    },

    alert(message, options = {}) {
      assertion('message', message);

      return this.add('alert', null, Object.assign(options, { message }));
    },

    confirm(message, options = {}) {
      assertion('message', message);

      return this.add('confirm', null, Object.assign(options, { message }));
    },

    create(name, model = {}, options = {}) {
      assertion('name', name);

      let service = this;
      let modal = _modal.default.create({
        model,
        name,
        options,
        service
      });

      return modal;
    },

    hide(name) {
      this.get('modalStack').slice().reverse().forEach(modal => {
        if (modal.get('name') === name) {
          modal.hide();
        }
      });

      return this;
    },

    hideAll() {
      this.get('modalStack').slice().reverse().forEach(modal => {
        modal.hide();
      });

      return this;
    },

    hideUpper() {
      let upperModal = this.get('upperModal');

      if (upperModal) {
        upperModal.hide();
      }

      return this;
    },

    show() {
      return this.create(...arguments).show();
    }
  });
});