define('bgr-ember-modal/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    modalNameFormat: 'x-%@-modal',
    modalNamespace: 'modals',
    modalWait: 20
  };
});