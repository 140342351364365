define("bgr-ember-flash/templates/components/bgr-flash-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UEfAPRUR",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"instance\"],[[30,1]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[\"flashInstance\",\"&default\"],false,[\"each\",\"-track-array\",\"flashStack\",\"if\",\"has-block\",\"yield\",\"bgr-flash\"]]",
    "moduleName": "bgr-ember-flash/templates/components/bgr-flash-stack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});