define("ember-light-table/templates/components/lt-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MpESWcM5",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"classNames\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollToY\",\"onScrollY\"],[\"lt-scrollable\",[33,3],[33,4],[33,5],[33,6],[33,7]]],[[\"default\"],[[[[1,\"    \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]]]]]],[]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]]]],[\"scrollbar\",\"&default\"],false,[\"if\",\"virtualScrollbar\",\"ember-scrollable\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollTo\",\"onScrollY\",\"yield\"]]",
    "moduleName": "ember-light-table/templates/components/lt-scrollable.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});