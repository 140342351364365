define("ember-app-scheduler/scheduler", ["exports", "@ember/test-waiters"], function (_exports, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.beginTransition = beginTransition;
  _exports.default = void 0;
  _exports.endTransition = endTransition;
  _exports.reset = reset;
  _exports.routeSettled = routeSettled;
  _exports.setupRouter = setupRouter;
  _exports.whenRouteIdle = whenRouteIdle;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const APP_SCHEDULER_LABEL = 'ember-app-scheduler';
  const APP_SCHEDULER_HAS_SETUP = '__APP_SCHEDULER_HAS_SETUP__';

  let _whenRouteDidChange;

  let _whenRouteIdle;

  const IS_FASTBOOT = typeof window.FastBoot !== 'undefined';
  const waiter = (0, _testWaiters.buildWaiter)('ember-app-scheduler-waiter');
  reset();
  let Scheduler = (_dec = Ember._tracked, (_class = class Scheduler {
    constructor() {
      _initializerDefineProperty(this, "isIdle", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isIdle", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  const scheduler = new Scheduler();
  var _default = scheduler;
  /**
   * Initializes the top level promise that initiates the post-render work.
   *
   * @public
   * @function beginTransition
   * @return {void}
   */

  _exports.default = _default;

  function beginTransition() {
    if (_whenRouteDidChange.isResolved) {
      _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
      _whenRouteIdle = _whenRouteDidChange.promise.then(() => {
        let scheduledWorkToken = waiter.beginAsync();
        return new Ember.RSVP.Promise(resolve => {
          Ember.run.schedule('afterRender', null, () => {
            requestAnimationFrame(() => {
              requestAnimationFrame(resolve);
            });
          });
        }).finally(() => {
          waiter.endAsync(scheduledWorkToken);
          mark('appSchedulerEnd');
          measure('appScheduler', 'appSchedulerStart', 'appSchedulerEnd');
        });
      });
      scheduler.isIdle = false;
    }
  }
  /**
   * Initiates the post-render work.
   *
   * @public
   * @function endTransition
   * @return {void}
   */


  function endTransition() {
    _whenRouteDidChange.resolve();

    scheduler.isIdle = true;
    mark('appSchedulerStart');
  }
  /**
   * Connects the router's transition events to
   * app scheduler's work.
   *
   * @public
   * @function setupRouter
   * @param {RouterService|Router} router An instance of a RouterService or an Ember Router.
   * @return {void}
   */


  function setupRouter(router) {
    if (IS_FASTBOOT || router[APP_SCHEDULER_HAS_SETUP]) {
      return;
    }

    router[APP_SCHEDULER_HAS_SETUP] = true; // @ts-ignore

    Ember.addListener(router, 'routeWillChange', beginTransition); // @ts-ignore

    Ember.addListener(router, 'routeDidChange', endTransition);

    Ember._registerDestructor(router, reset);
  }
  /**
   * Resets the state of app scheduler's top-level scheduled work promise.
   *
   * @public
   * @function reset
   * @return {void}
   */


  function reset() {
    _whenRouteDidChange = _defer(APP_SCHEDULER_LABEL);
    _whenRouteIdle = _whenRouteDidChange.promise.then();
    waiter.reset();

    if (!IS_FASTBOOT) {
      _whenRouteDidChange.resolve();
    }
  }
  /**
   * This promise, when resolved, approximates after content is painted.
   *
   * @public
   * @function whenRouteIdle
   * @return {Promise<any>} The scheduled work promise.
   */


  function whenRouteIdle() {
    return _whenRouteIdle;
  }
  /**
   * Allows for tests to pause until the scheduled work
   * promise is completed.
   *
   * @public
   * @function routeSettled
   * @return {Promise<any>} The scheduled work promise.
   */


  function routeSettled() {
    return _whenRouteIdle;
  }

  function _defer(label) {
    let _isResolved = false;

    let _resolve;

    let _reject;

    const promise = new Ember.RSVP.Promise((resolve, reject) => {
      _resolve = () => {
        _isResolved = true;
        resolve();
      };

      _reject = reject;
    }, label);
    return {
      promise,
      resolve: _resolve,
      reject: _reject,

      get isResolved() {
        return _isResolved;
      }

    };
  }

  function mark(markName) {
    try {
      performance.mark(markName);
    } catch (ex) {
      console.warn(`performance.mark could not be executed because of ${ex.message}`);
    }
  }

  function measure(measureName, startMark, endMark) {
    try {
      performance.measure(measureName, startMark, endMark);
    } catch (ex) {
      console.warn(`performance.measure could not be executed because of ${ex.message}`);
    }
  }
});