define("@bagaar/ember-pagination/components/pagination-data/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield
    (hash
      activeItems=this.activeItems
      allPages=this.allPages
      currentPage=this._currentPage
      endMarginPages=this.endMarginPages
      firstActiveItem=this.firstActiveItem
      isFirstPage=this.isFirstPage
      isLastPage=this.isLastPage
      itemsPerPage=this.itemsPerPage
      lastActiveItem=this.lastActiveItem
      lastPage=this.lastPage
      nextPage=this.nextPage
      pageMargins=this.pageMargins
      pageRange=this.pageRange
      pageRangePages=this.pageRangePages
      previousPage=this.previousPage
      shouldShowLowerBreak=this.shouldShowLowerBreak
      shouldShowUpperBreak=this.shouldShowUpperBreak
      startMarginPages=this.startMarginPages
      totalItems=this.totalItems
      totalPages=this.totalPages
    )
  }}
  */
  {
    "id": "DDbmMoHr",
    "block": "[[[18,1,[[28,[37,1],null,[[\"activeItems\",\"allPages\",\"currentPage\",\"endMarginPages\",\"firstActiveItem\",\"isFirstPage\",\"isLastPage\",\"itemsPerPage\",\"lastActiveItem\",\"lastPage\",\"nextPage\",\"pageMargins\",\"pageRange\",\"pageRangePages\",\"previousPage\",\"shouldShowLowerBreak\",\"shouldShowUpperBreak\",\"startMarginPages\",\"totalItems\",\"totalPages\"],[[30,0,[\"activeItems\"]],[30,0,[\"allPages\"]],[30,0,[\"_currentPage\"]],[30,0,[\"endMarginPages\"]],[30,0,[\"firstActiveItem\"]],[30,0,[\"isFirstPage\"]],[30,0,[\"isLastPage\"]],[30,0,[\"itemsPerPage\"]],[30,0,[\"lastActiveItem\"]],[30,0,[\"lastPage\"]],[30,0,[\"nextPage\"]],[30,0,[\"pageMargins\"]],[30,0,[\"pageRange\"]],[30,0,[\"pageRangePages\"]],[30,0,[\"previousPage\"]],[30,0,[\"shouldShowLowerBreak\"]],[30,0,[\"shouldShowUpperBreak\"]],[30,0,[\"startMarginPages\"]],[30,0,[\"totalItems\"]],[30,0,[\"totalPages\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
    "moduleName": "@bagaar/ember-pagination/components/pagination-data/index.hbs",
    "isStrictMode": false
  });

  const DISABLED = null;
  const FIRST_PAGE = 1;

  class PaginationDataComponent extends _component.default {
    /**
     * Argument getters
     */
    get currentPage() {
      (true && !(isNumber(this.args.currentPage)) && Ember.assert(`@currentPage is required and must be a number. You provided \`${this.args.currentPage}\`.`, isNumber(this.args.currentPage)));
      (true && !(this.args.currentPage >= FIRST_PAGE && this.args.currentPage <= this.lastPage) && Ember.assert(`@currentPage must be a value between \`${FIRST_PAGE}\` and \`${this.lastPage}\`. You provided \`${this.args.currentPage}\`.`, this.args.currentPage >= FIRST_PAGE && this.args.currentPage <= this.lastPage));
      return this.args.currentPage;
    }

    get itemsPerPage() {
      (true && !(isNumber(this.args.itemsPerPage)) && Ember.assert(`@itemsPerPage is required and must be a number. You provided \`${this.args.itemsPerPage}\`.`, isNumber(this.args.itemsPerPage)));
      return this.args.itemsPerPage;
    }

    get pageMargins() {
      if (isNumber(this.args.pageMargins) === false) {
        return 1;
      }

      (true && !(this.args.pageMargins > 0) && Ember.assert(`@pageMargins must be a number higher than \`0\`. You provided \`${this.args.pageMargins}\`.`, this.args.pageMargins > 0));
      return this.args.pageMargins;
    }

    get pageRange() {
      if (isNumber(this.args.pageRange) === false) {
        return null;
      }

      (true && !(this.args.pageRange % 2 !== 0) && Ember.assert(`@pageRange must be an uneven number to make sure that the active page is always center aligned. You provided \`${this.args.pageRange}\`.`, this.args.pageRange % 2 !== 0));
      return this.args.pageRange;
    }

    get totalItems() {
      (true && !(isNumber(this.args.totalItems)) && Ember.assert(`@totalItems is required and must be a number. You provided \`${this.args.totalItems}\`.`, isNumber(this.args.totalItems)));
      return this.args.totalItems;
    }
    /**
     * State getters
     */


    get _currentPage() {
      return clamp(this.currentPage, FIRST_PAGE, this.lastPage);
    }

    get activeItems() {
      if (this.itemsPerPage * this._currentPage > this.totalItems) {
        return this.totalItems % this.itemsPerPage;
      }

      return this.itemsPerPage;
    }

    get allPages() {
      return range(FIRST_PAGE, this.lastPage);
    }

    get endMarginPages() {
      if (this.pageRange === null) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return [];
      }

      return range(this.pageRangeUpperLimit + 1, this.totalPages);
    }

    get firstActiveItem() {
      if (this.totalItems > 0) {
        return this._currentPage * this.itemsPerPage - this.itemsPerPage + 1;
      }

      return 0;
    }

    get isFirstPage() {
      return this._currentPage === FIRST_PAGE;
    }

    get isLastPage() {
      return this._currentPage === this.lastPage;
    }

    get lastActiveItem() {
      if (this.totalItems > 0) {
        return this.firstActiveItem + this.activeItems - 1;
      }

      return 0;
    }

    get lastPage() {
      return this.totalPages;
    }

    get nextPage() {
      return this.isLastPage ? DISABLED : this._currentPage + 1;
    }

    get pageMarginsThreshold() {
      return this.pageRange + this.pageMargins * 2;
    }

    get pageRangeLowerLimit() {
      return this.pageMargins + 1;
    }

    get pageRangePages() {
      if (this.pageRange === null) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return this.allPages;
      }

      const pageRangeOffset = Math.floor(this.pageRange / 2);
      let pageRangeStart = this._currentPage - pageRangeOffset;
      let pageRangeEnd = this._currentPage + pageRangeOffset;

      if (pageRangeStart < this.pageRangeLowerLimit) {
        pageRangeStart = this.pageRangeLowerLimit;
        pageRangeEnd = pageRangeStart + this.pageRange - 1;
      }

      if (pageRangeEnd > this.pageRangeUpperLimit) {
        pageRangeStart = this.pageRangeUpperLimit - this.pageRange + 1;
        pageRangeEnd = this.pageRangeUpperLimit;
      }

      return range(pageRangeStart, pageRangeEnd);
    }

    get pageRangeUpperLimit() {
      return this.totalPages - this.pageMargins;
    }

    get previousPage() {
      return this.isFirstPage ? DISABLED : this._currentPage - 1;
    }

    get shouldShowLowerBreak() {
      if (this.pageRange === null) {
        return false;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return false;
      }

      return this.pageRangePages.length && this.pageRangePages[0] !== this.pageRangeLowerLimit;
    }

    get shouldShowUpperBreak() {
      if (this.pageRange === null) {
        return false;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return false;
      }

      return this.pageRangePages.length && this.pageRangePages[this.pageRangePages.length - 1] !== this.pageRangeUpperLimit;
    }

    get startMarginPages() {
      if (this.pageRange === null) {
        return null;
      }

      if (this.totalPages < this.pageMarginsThreshold) {
        return [];
      }

      return range(FIRST_PAGE, this.pageMargins);
    }

    get totalPages() {
      const totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
      return isNaN(totalPages) || totalPages === 0 ? 1 : totalPages;
    }

  }

  _exports.default = PaginationDataComponent;

  function clamp(number, min, max) {
    return Math.min(Math.max(number, min), max);
  }

  function isNumber(value) {
    return typeof value === 'number' && isNaN(value) === false;
  }

  function range(start, end) {
    return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
  }

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PaginationDataComponent);
});