define("bgr-ember-flash/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    flashConfig: {
      isSticky: false,
      priority: 100,
      timeout: 5000
    },
    flashLevels: {
      danger: {
        isSticky: true
      },
      info: {},
      success: {},
      warning: {}
    },
    flashWait: 20
  };
  _exports.default = _default;
});