define('bgr-ember-modal/initializers/bgr-ember-modal', ['exports', 'bgr-ember-addon-blueprint/utils/merge-config', 'bgr-ember-modal/config'], function (exports, _mergeConfig, _config) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    let environment = application.resolveRegistration('config:environment') || {};

    (0, _mergeConfig.default)(_config.default, environment['bgr-ember-modal']);
  }

  exports.default = { initialize };
});