define("ember-popper/components/ember-popper", ["exports", "ember-popper/components/ember-popper-base"], function (_exports, _emberPopperBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberPopperBase.default.extend({
    /**
     * The element the popper will target.
     * @argument
     * @type(Element)
     */
    popperTarget: null,

    // ================== LIFECYCLE HOOKS ==================
    init() {
      this.id = this.id || `${Ember.guidFor(this)}-popper`;

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});