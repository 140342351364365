define('bgr-ember-modal/components/bgr-modal', ['exports', 'bgr-ember-modal/config', 'bgr-ember-modal/templates/components/bgr-modal'], function (exports, _config, _bgrModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * props
     */

    instance: null,

    /**
     * state
     */

    classNameBindings: ['isActive:is-active:is-inactive'],
    classNames: ['modal'],
    isActive: false,
    later: null,
    layout: _bgrModal.default,

    /**
     * custom hooks
     */

    beforeShow() {},

    afterShow() {},

    beforeHide() {},

    /**
     * computed
     */

    model: Ember.computed.alias('instance.model'),
    options: Ember.computed.readOnly('instance.options'),

    /**
     * hooks
     */

    willInsertElement() {
      this._super(...arguments);

      this.beforeShow();
    },

    didInsertElement() {
      this._super(...arguments);

      this.afterShow();

      this.set('later', Ember.run.later(() => {
        this.set('isActive', true);
      }, _config.default.modalWait));
    },

    /**
     * actions
     */

    actions: {
      hide() {
        this.hide();
      },
      reject(error) {
        this.reject(error);
      },
      resolve(model) {
        this.resolve(model);
      }
    },

    /**
     * methods
     */

    click() {
      this._super(...arguments);

      let event = arguments[0];

      if (event.target === this.get('element')) {
        this.clickOutside();
      }
    },

    clickOutside() {
      this.hide();
    },

    hide() {
      this.get('instance').hide();
    },

    reject(error) {
      this.get('instance').reject(error);
    },

    resolve(model) {
      this.get('instance').resolve(model);
    },

    willDestroy() {
      this._super(...arguments);

      this.beforeHide();

      Ember.run.cancel(this.get('later'));
    }
  });
});