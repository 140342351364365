define("@bagaaravel/ember-data-extensions/model", ["exports", "@bagaaravel/ember-data-extensions/-private/config", "@bagaaravel/ember-data-extensions/-private/utils"], function (_exports, _config, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saveRelationship = saveRelationship;
  _exports.saveRelationships = saveRelationships;

  function saveRelationship(record, relationshipName) {
    (true && !(!record.isNew) && Ember.assert('@bagaaravel/ember-data-extensions: Cannot save a relationship of a newly created record.', !record.isNew));
    (true && !((0, _utils.getRelationshipDescriptor)(record, relationshipName)) && Ember.assert(`@bagaaravel/ember-data-extensions: "${relationshipName}" is not a valid relationship name.`, (0, _utils.getRelationshipDescriptor)(record, relationshipName)));
    (true && !(canSerializeRelationship(record, relationshipName)) && Ember.assert(`@bagaaravel/ember-data-extensions: "${relationshipName}" relationship can not be serialized.`, canSerializeRelationship(record, relationshipName)));
    return record.save({
      adapterOptions: {
        [_config.RELATIONSHIP_ADAPTER_OPTION]: relationshipName
      }
    });
  }

  function saveRelationships(record, relationshipNames) {
    (true && !(!record.isNew) && Ember.assert('@bagaaravel/ember-data-extensions: Cannot save relationships of a newly created record.', !record.isNew));
    const promises = relationshipNames.map(relationshipName => saveRelationship(record, relationshipName));
    return Promise.all(promises);
  }

  function canSerializeRelationship(record, relationshipName) {
    const serializer = record.store.serializerFor(record.constructor.modelName);
    const {
      attrs
    } = serializer;
    return !attrs || !attrs[relationshipName] || attrs[relationshipName].serialize !== false;
  }
});