define("ember-data-storefront/services/storefront", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // do not delete this service! it's being used to communicte cached payloads
  // between the client and the browser
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    fastbootDataRequests: null,

    init() {
      this._super(...arguments);

      this.set('fastbootDataRequests', {});
    },

    findAll() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-find-all',
        until: '1.0.0'
      }));
      return this.get('store').loadAll(...arguments);
    },

    loadAll() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadAll instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-load-all',
        until: '1.0.0'
      }));
      return this.get('store').loadAll(...arguments);
    },

    findRecord() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-find-record',
        until: '1.0.0'
      }));
      return this.get('store').findRecord(...arguments);
    },

    loadRecord() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.loadRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-load-record',
        until: '1.0.0'
      }));
      return this.get('store').findRecord(...arguments);
    },

    hasLoadedIncludesForRecord() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.hasLoadedIncludesForRecord instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-has-loaded-includes-for-record',
        until: '1.0.0'
      }));
      return this.get('store').hasLoadedIncludesForRecord(...arguments);
    },

    resetCache() {
      (true && !(false) && Ember.deprecate('The storefront service has been deprecated, please use store.resetCache instead. Will be removed in 1.0.', false, {
        id: 'ember-data-storefront.storefront-reset-cache',
        until: '1.0.0'
      }));
      return this.get('store').resetCache(...arguments);
    }

  });

  _exports.default = _default;
});