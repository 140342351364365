define("ember-composable-helpers/helpers/call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.call = call;
  _exports.default = void 0;

  /**
   * Calls a function passed within a template and returns its value.
   * In order to pass arguments to the function being called, you must
   * curry the function using the `fn` helper.
   *
   ```example
      <div data-metrics={{call (fn this.myMetrics (hash item=@item))}}
    ```
   *
   * @function apply
   * @param {Array<Function>} fn - The function to be called
   * @param {*=} thisArg - An optional `this` context
   */
  function call(_ref) {
    let [fn, thisArg] = _ref;

    if (fn) {
      if (thisArg) {
        return fn.apply(thisArg);
      } else {
        return fn();
      }
    }
  }

  var _default = Ember.Helper.helper(call);

  _exports.default = _default;
});