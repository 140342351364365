define("ember-light-table/utils/closest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A polyfill for jQuery .closest() method
   * @param  { Object } el     Dom element to start from
   * @param  { String } selector Selector to match
   * @return { Object }          The closest matching node or null
   */
  const closest = (el, selector) => {
    let parent;

    while (el) {
      parent = el.parentElement;

      if (parent && parent.matches(selector)) {
        return parent;
      }

      el = parent;
    }

    return null;
  };

  var _default = closest;
  _exports.default = _default;
});