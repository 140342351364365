define("@bagaar/ember-permissions/instance-initializers/ember-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(applicationInstance) {
    const permissionsService = applicationInstance.lookup('service:permissions');
    permissionsService.cacheInitialTransition();
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});