define("ember-page-title/services/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PageTitleService extends Ember.Service {
    titleDidUpdate() {}

  }

  _exports.default = PageTitleService;
});