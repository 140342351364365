define("@bagaar/ember-permissions/services/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PermissionsService = (_dec = Ember.inject.service('router'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class PermissionsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "routerService", _descriptor, this);

      _initializerDefineProperty(this, "permissions", _descriptor2, this);

      _initializerDefineProperty(this, "routePermissions", _descriptor3, this);

      _defineProperty(this, "initialTransition", null);

      _defineProperty(this, "isRouteValidationEnabled", false);
    }

    on(name, target, handler) {
      Ember.addListener(this, name, target, handler);
    }

    one(name, target, handler) {
      Ember.addListener(this, name, target, handler, true);
    }

    off(name, target, handler) {
      Ember.removeListener(this, name, target, handler);
    }

    trigger(name) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      Ember.sendEvent(this, name, args);
    }

    setPermissions(permissions) {
      (true && !(permissions && Array.isArray(permissions)) && Ember.assert('`permissions` is required and should be an array.', permissions && Array.isArray(permissions)));
      this.permissions = permissions;
    }

    setRoutePermissions(routePermissions) {
      (true && !(routePermissions && typeof routePermissions === 'object') && Ember.assert('`routePermissions` is required and should be an object.', routePermissions && typeof routePermissions === 'object'));
      this.routePermissions = routePermissions;
    }

    cacheInitialTransition() {
      this.routerService.one('routeWillChange', transition => {
        this.initialTransition = transition;
      });
      this.routerService.one('routeDidChange', () => {
        this.initialTransition = null;
      });
    }

    hasPermissions(permissions) {
      (true && !(permissions && Array.isArray(permissions)) && Ember.assert('`permissions` is required and should be an array.', permissions && Array.isArray(permissions)));
      return permissions.every(permission => {
        return this.permissions.includes(permission);
      });
    }

    canAccessRoute(routeName) {
      (true && !(routeName && typeof routeName === 'string') && Ember.assert('`routeName` is required and should be a string.', routeName && typeof routeName === 'string'));
      const routeTreePermissions = this.getRouteTreePermissions(routeName);
      return this.hasPermissions(routeTreePermissions);
    }

    getRouteTreePermissions(routeName) {
      const routeNameSplitted = routeName.split('.');
      const routeTreePermissions = [];

      for (let index = 0; index < routeNameSplitted.length; index++) {
        const routeNameJoined = routeNameSplitted.slice(0, index + 1).join('.');
        const routePermissions = this.routePermissions[routeNameJoined];

        if (routePermissions) {
          routeTreePermissions.push(...routePermissions);
        }
      }

      return routeTreePermissions;
    }

    enableRouteValidation() {
      if (this.isRouteValidationEnabled === true) {
        return;
      }

      this.isRouteValidationEnabled = true;
      this.validateTransition(this.initialTransition);
      this.routerService.on('routeWillChange', this.validateTransition);
    }

    validateTransition(transition) {
      const routeName = transition?.to?.name;

      if (routeName && this.canAccessRoute(routeName) === false) {
        this.trigger('route-access-denied', transition);
      }
    }

    willDestroy() {
      if (this.isRouteValidationEnabled === true) {
        this.routerService.off('routeWillChange', this.validateTransition);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "routerService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permissions", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "routePermissions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validateTransition", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validateTransition"), _class.prototype)), _class));
  _exports.default = PermissionsService;
});