define("bgr-ember-flash/components/bgr-flash-stack", ["exports", "bgr-ember-flash/templates/components/bgr-flash-stack"], function (_exports, _bgrFlashStack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * state
     */
    bgrFlash: Ember.inject.service(),
    classNames: ['flash-stack'],
    layout: _bgrFlashStack.default,

    /**
     * computed
     */
    flashStack: Ember.computed.sort('bgrFlash.flashStack', function (flashA, flashB) {
      let flashAPriority = flashA.get('options.priority');
      let flashBPriority = flashB.get('options.priority');

      if (flashAPriority < flashBPriority) {
        return 1;
      } else if (flashAPriority > flashBPriority) {
        return -1;
      }

      return 0;
    }).readOnly()
  });

  _exports.default = _default;
});