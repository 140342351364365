define('bgr-ember-modal/components/bgr-modal-template', ['exports', 'bgr-ember-modal/templates/components/bgr-modal-template'], function (exports, _bgrModalTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function sendAction(actionName) {
    return function () {
      this.sendAction(actionName); // eslint-disable-line
    };
  }

  exports.default = Ember.Component.extend({
    /**
     * state
     */

    layout: _bgrModalTemplate.default,
    tagName: '',

    hide: 'hide',
    reject: 'reject',
    resolve: 'resolve',

    /**
     * actions
     */

    actions: {
      hide: sendAction('hide'),
      reject: sendAction('reject'),
      resolve: sendAction('resolve')
    }
  });
});