define('ember-custom-actions/utils/normalize-payload', ['exports', '@ember/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (payload, operation) {
    if (operation) {
      (true && !(!!transformFunctions[operation]) && Ember.assert("This normalize method of custom action's payload does not exist. Check Ember.String documentation!", !!transformFunctions[operation]));

      return transformObject(payload, operation);
    } else {
      return payload;
    }
  };

  const transformFunctions = {
    camelize: _string.camelize,
    capitalize: _string.capitalize,
    classify: _string.classify,
    dasherize: _string.dasherize,
    decamelize: _string.decamelize,
    underscore: _string.underscore
  };

  function transformObject(object, operation) {
    if (object instanceof Object && !Ember.isArray(object)) {
      let data = {};

      Object.keys(object).forEach(key => {
        let transform = transformFunctions[operation];
        data[transform(key)] = transformObject(object[key], operation);
      });

      return data;
    } else {
      return object;
    }
  }
});